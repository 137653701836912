import React from "react";

import { OMNIS_NOVY_ODBER_SLUG } from "../../constants";
import OmnisLP from "../../components/OmnisLP";
import { OmnisData } from "../../content/OmnisData";
// import { graphql, useStaticQuery } from "gatsby";

const NovyOdber = () => {
  // const articles = useStaticQuery(graphql`
  //   query NovyOdberQuery {
  //     allNews(filter: { newsId: { in: [118] } }) {
  //       edges {
  //         node {
  //           id
  //           name
  //           newsId
  //           perex
  //           slug
  //           descriptionImage
  //           releaseDate {
  //             date
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  return <OmnisLP contentData={OmnisData[OMNIS_NOVY_ODBER_SLUG]} articles={[]} />;
};

export default NovyOdber;
